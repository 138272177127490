import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["focus"]

    focus(event) {
        this.focusTarget.focus();
    }

}
