import { Controller } from "@hotwired/stimulus";
import * as Sentry from "@sentry/browser";

export default class extends Controller {
  static values = {
    dsn: String,
    environment: String,
    release: String,
  };

  connect() {
    Sentry.init({
      dsn: this.dsnValue,
      environment: this.environmentValue,
      release: this.releaseValue,
      sendDefaultPii: true,
    });
    Sentry.setUser(Current.petOwner);
  }
}
