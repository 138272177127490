import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "preview"];

  preview() {
    if (!this.#image) return;

    this.#updatePreviewWithImage(this.#image);
  }

  get #image() {
    const input = this.inputTarget;
    return input.files && input.files.length > 0 ? input.files[0] : null;
  }

  #updatePreviewWithImage(image) {
    this.previewTarget.src = URL.createObjectURL(image)
    this.previewTarget.onload = () => {
      URL.revokeObjectURL(this.previewTarget.src)
    }
  }
}
