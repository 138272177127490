import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.adjustHeight();
  }

  adjustHeight() {
    this.element.style.height = "auto"; // scrollHeightを計算させる
    const borderWidth = parseInt(
      getComputedStyle(this.element).borderWidth,
      10,
    );
    this.element.style.height = `${this.element.scrollHeight + 2 * borderWidth}px`;
  }
}
