import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dialog'];

  connect() {
    // NOTE: turboで遷移した場合も閉じるようにする
    this.close();
  }

  open() {
    document.body.classList.add("overflow-hidden");
    this.dialogTarget.showModal();
  }

  close() {
    this.dialogTarget.close();
    document.body.classList.remove("overflow-hidden");
  }

  toggle() {
    if (this.dialogTarget.open) {
      this.close();
    } else {
      this.open();
    }
  }
}
