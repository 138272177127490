import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  throwError() {
    throw "a simulated error from js";
  }

  nullError() {
    const aNull = null;
    aNull.aMethodOfNull();
  }

  unknownRefError() {
    this.imaginaryMethod();
  }
}
