import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source"]

  async copy() {
    this.sourceTarget.select()
    try {
      await navigator.clipboard.writeText(this.sourceTarget.value)
      this.onCopySuccess()
    } catch (err) {
      console.error("Failed to copy", err)
    }
  }

  onCopySuccess() {
    this.sourceTarget.classList.add("copied")
    setTimeout(() => {
      this.sourceTarget.classList.remove("copied")
    }, 1000)
  }
}
