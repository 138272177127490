// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./initializers"
import "./controllers"
import "../../vendor/assets/javascripts/@fontawesome/fontawesome-pro/all.min.js";

// NOTE: FontAwesomeがページ読み込み時にチラつく問題への対応
// https://docs.fontawesome.com/web/use-with/rails-turbolinks/
FontAwesome.config.mutateApproach = "sync";

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
