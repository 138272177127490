import { Controller } from "@hotwired/stimulus"

// TODO(oljfte): actioncableでbroadcastしたformはCSRFトークンがないが、
// 受信後にどうセットし直せばわからなかったので、送信時にmetaタグから借りてworkaroundしている。
// 適切なやり方が分かり次第消す。
export default class extends Controller {
  connect() {
    this.element.addEventListener("submit", this.onSubmit.bind(this))
  }

  onSubmit(event) {
    event.preventDefault()

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content
    const hiddenInput = document.createElement("input")
    hiddenInput.type = "hidden"
    hiddenInput.name = "authenticity_token"
    hiddenInput.value = csrfToken
    this.element.appendChild(hiddenInput)

    this.element.submit()
  }
}
