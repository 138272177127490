import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    title: String,
    text: String,
    url: String
  }

  async share(event) {
    const shareData = {
      title: this.titleValue,
      text: this.textValue,
      url: this.urlValue
    };

    try {
      await navigator.share(shareData);
    } catch (err) {
      console.error("Failed to share", err)
    }
  }
}
