import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.pushToDataLayer({
      event: "connect",
      elementId: this.element.id,
    });
  }

  pushToDataLayer(data) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }
}
